import React from 'react'
import {useGenericCta} from 'src/hooks/useGenericCta'
import FooterV2 from 'src/components/shared/src/ComponentsV2/common/FooterV2'
import {useDynamicFooterData} from 'src/hooks/useDynamicFooterData'

function Footer(props: any) {
  const {footerData = {}} = props
  const {handleCta} = useGenericCta()
  const {dyFooterData} = useDynamicFooterData()

  return (
    <FooterV2
      footerData={footerData}
      dynamicData={dyFooterData}
      handleCta={handleCta}
    />
  )
}

export default Footer
