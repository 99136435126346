import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import noop from 'lodash/noop'
import React, {useCallback, useMemo} from 'react'

import {getLinkToRender} from '../../../utils'
import OptimizedImage from '../OptimizedImage/OptimizedImage'
import {Footer} from './FooterV2.styles'

function FooterV2(props) {
  const {footerData = {}, dynamicData = {}, handleCta = noop} = props

  const {
    backgroundColor = '#ffffff',
    fontColor = '#000000',
    borderColor = '#000000',
    logo = {},
    socialMedia = [],
    appLinks = [],
    copyrightText = '',
    termsOfUse = {},
    sections = [],
  } = footerData

  const {brandLogoLink = '', altText = '', link = ''} = logo
  const brandLink = useMemo(() => {
    return getLinkToRender(link)
  }, [link])
  const onItemClick = useCallback(
    (e, cta) => {
      e.preventDefault()
      handleCta(cta)
    },
    [handleCta]
  )

  const appLinksMapping = useMemo(() => {
    if (!appLinks.length) return null
    return (
      <div className="mobile-apps-container">
        {appLinks.map((app) => {
          const {label = '', link = '', icon = ''} = app
          const linkToRender = getLinkToRender(link)
          return (
            <a
              href={linkToRender}
              className="mobile-app-image-conatainer"
              onClick={(e) => onItemClick(e, app)}
              key={label}
            >
              <OptimizedImage
                source={icon}
                aspectWidth="146"
                aspectHeight="52"
                desktopViewWidth="SMALL"
                mobileViewWidth="SMALL"
                altText={label}
                loading="lazy"
                customClassName="mobile-app-image"
              />
            </a>
          )
        })}
      </div>
    )
  }, [appLinks, onItemClick])

  const sectionsMapping = useMemo(() => {
    if (!sections.length) return null

    return sections.map((section) => {
      return (
        <div className="website-sections-container">
          {section.map((item) => {
            const {label = '', link = ''} = item
            const linkToRender = getLinkToRender(link)
            return (
              <a
                className="website-section-name"
                href={linkToRender}
                onClick={(e) => onItemClick(e, item)}
                key={label}
              >
                {label}
              </a>
            )
          })}
        </div>
      )
    })
  }, [sections, onItemClick])

  const termsConditionMapping = useMemo(() => {
    if (isEmpty(termsOfUse)) return null
    const {label = '', link = ''} = termsOfUse || {}
    const linkToRender = getLinkToRender(link)
    return (
      <a
        className="terms-of-service-text"
        href={linkToRender}
        onClick={(e) => onItemClick(e, termsOfUse)}
      >
        {label}
      </a>
    )
  }, [termsOfUse, onItemClick])

  const socialMediaMapping = useMemo(() => {
    if (!socialMedia.length) return null
    return (
      <div className="social-media-icons-container">
        {socialMedia.map((item) => {
          const {label = '', link = '', icon = ''} = item
          const linkToRender = getLinkToRender(link)
          return (
            <a
              href={linkToRender}
              onClick={(e) => onItemClick(e, item)}
              className="social-icon-container"
              key={label}
            >
              <OptimizedImage
                source={icon}
                aspectWidth="22"
                aspectHeight="22"
                desktopViewWidth="TINY"
                mobileViewWidth="TINY"
                altText={label}
                loading="lazy"
                customClassName="social-icon-image"
              />
            </a>
          )
        })}
      </div>
    )
  }, [socialMedia, onItemClick])

  const dynamicFooterDataMapping = useMemo(() => {
    if (
      isEmpty(dynamicData) ||
      (isEmpty(dynamicData?.internalLinks) &&
        isEmpty(dynamicData?.footerContentSection))
    )
      return null

    const {internalLinks, footerContentSection} = dynamicData

    return (
      <div className="dynamic-footer-container">
        {internalLinks?.length ? (
          <>
            <div className="dynamic-footer-links-container">
              {map(internalLinks, (_link, _index) => {
                const linkToRender = getLinkToRender(_link?.cta)
                return (
                  <>
                    <a href={linkToRender} key={_link?.title}>
                      {_link?.title}
                    </a>
                    {_index + 1 != internalLinks?.length ? <div>|</div> : ''}
                  </>
                )
              })}
            </div>
          </>
        ) : null}
        {!isEmpty(footerContentSection) ? (
          <>
            <div
              className="dynamic-footer-content-container"
              dangerouslySetInnerHTML={{
                __html: footerContentSection,
              }}
            ></div>
          </>
        ) : null}
      </div>
    )
  }, [dynamicData])

  return (
    <Footer
      backgroundColor={backgroundColor}
      fontColor={fontColor}
      borderColor={borderColor}
      id="website-footer"
    >
      <div className="footer-container">
        <a
          className="brand-logo-container"
          href={brandLink}
          onClick={(e) => onItemClick(e, logo)}
        >
          <OptimizedImage
            source={brandLogoLink}
            aspectWidth="140"
            aspectHeight="60"
            desktopViewWidth="SMALL"
            mobileViewWidth="SMALL"
            altText={altText}
            loading="lazy"
            customClassName="brand-logo"
          />
        </a>
        {appLinksMapping}
        {sectionsMapping}
        <label className="copy-right-text">{copyrightText}</label>
        {termsConditionMapping}
        {socialMediaMapping}
        {dynamicFooterDataMapping}
      </div>
    </Footer>
  )
}

export default React.memo(FooterV2)
