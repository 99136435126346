import {create} from 'zustand'

interface IDynamicFooterData {
  internalLinks?: {
    cta: string
    title: string
  }[]
  footerContentSection?: string
}

interface IDynamicFooterHook {
  dyFooterData: IDynamicFooterData | null
  setDyFooterData: (_footerData: IDynamicFooterData | null) => void
}

export const useDynamicFooterData = create<IDynamicFooterHook>((set) => ({
  dyFooterData: null,
  setDyFooterData: (by) => set((state) => ({dyFooterData: by})),
}))
